<template>
    <iframe :src="src" frameborder="0" scrolling="no" :name="frameName" :style="{'height': `${height}px`}" :data-task-id="id"></iframe>
</template>
<script>
export default {
    name: 'ExamenVprTemplate',
    props: {
        model: Object,
        disabled: Boolean,
        mode: {
            type: String,
            default: ''
        },
        allowShowAnswer: {
            type: Boolean,
            default: null
        }
    },
    data() {
    	return {
            ready: false,
            height: 0,
            resizeObserver: null
        }
    },
    computed: {
        id() {
            return _.get(this, 'model.id', 0);
        },
        guid() {
            return Math.random().toString(36).substring(2, 15);
        },
        src() {
            return `/extra/examenvprtmpl/vpr_templates_z/index.html?guid=${this.guid}`;
        },
        frameName() {
            return _.join(['examen_vpr_template', _.now()], '_');
        }
    },
    methods: {
        onMessage(e) {
            try
            {
                const payload = JSON.parse(e.data);
                const event = _.get(payload, 'event', null);
                const guid = _.get(payload, 'guid', null);
                const data = _.get(payload, 'data', null);

                if(guid !== this.guid)
                    return ;

                console.log(event)

                switch(event)
                {
                    case 'ready':
                        this.onReady();
                    break;

                    case 'update':
                        this.onUpdate(data);
                    break;

                    case 'complete':
                        this.onComplete(data);
                    break;
                }

            }
            catch(err) {
                console.log(`Invalid data JSON with e.data value = ${_.get(e, 'data', '[EMPTY]')}`);
            }
            
            return ;
        },
        onReady() {
            const task = _.cloneDeep(_.get(this, 'model.data.dataBD', null));
            const answer = _.get(this, 'model.result_of_task.answer', null);
            const data = _.assign(task, {
                serializedData: answer
            });

            this.$el.contentWindow.postMessage(JSON.stringify({
                event: 'init',
                guid: this.guid,
                data: data,
                disabled: this.disabled,
                mode: this.mode,
                ...(!_.isNil(this.allowShowAnswer) ? {
                    allowShowAnswer: this.allowShowAnswer
                } : {})
            }), '*');
        },
        updateRequest() {
            this.$el.contentWindow.postMessage(JSON.stringify({
                event: 'update_request',
                guid: this.guid
            }), '*');
        },
        onUpdate(data) {
            this.$emit('update', data);
        },
        onComplete(data) {
            this.$emit('complete', data);
        },
        startIframeResizeObserve() {
            this.stopIframeResizeObserve();
            this.resizeObserver = setInterval(() => {
                // Calculate max height of document: body.scrollHeight OR max (y+height) of position: fixed|absolute elements
                const iframeBody = _.get(this, '$el.contentWindow.document.body', null);
                const maxHeight = Math.ceil(_.max([
                    _.get(iframeBody, 'scrollHeight', 0),
                    ..._.map([...(!_.isNil(iframeBody) ? iframeBody.getElementsByTagName("*") : [])].filter(
                        x => _.includes(['absolute', 'fixed'], getComputedStyle(x, null).getPropertyValue("position")) && getComputedStyle(x, null).getPropertyValue("display") !== "none"
                    ), (o) => {
                        const box = o.getBoundingClientRect();
                        return box.y + box.height;
                    })
                ]));

                if(maxHeight != this.height)
                    this.height = maxHeight;
            }, 250)
        },
        stopIframeResizeObserve() {
            if(!_.isNil(this.resizeObserver))
                clearInterval(this.resizeObserver);
        }
    },
    created() {
        window.addEventListener('message', this.onMessage);
    },
    mounted() {
        this.startIframeResizeObserve();
    },
    beforeDestroy() {
        window.removeEventListener('message', this.onMessage);
        this.stopIframeResizeObserve();
    }
}
</script>
<style lang="scss" scoped>
    iframe{
        width: 100%;
        // min-height: 50vh;
        @media print {
            page-break-before: avoid;
        }
    }
</style>