<template>
    <div class="view--print-option">
        <v-progress-circular
            class="view--print-option__waiting"
            :size="70"
            :width="7"
            color="cyan darken-2"
            indeterminate
        ></v-progress-circular>

        <job-task-list 
            :loading="loading" 
            :items="items" 
            mode="answer" 
            disabled 
            printable
            @complete="print" 
        />
    </div>
</template>
<script>
import JobTaskList from '@/components/app/JobTaskList.vue'
export default {
    name: 'PrintOption',
    components: { JobTaskList }, 
    data() {
        return {
            ready: false,
            loading: false,
            items: []
        }
    },
    computed: {
        subject() {
            return _.get(this, '$route.query.subject', '');
        },
        grade() {
            return _.get(this, '$route.query.grade', 0);
        },
        is_final() {
            return _.get(this, '$route.query.is_final', 0);
        },
        option() {
            return _.get(this, '$route.query.option', 1);
        }
    },
    methods: {
        async fetchTasks() {
			this.loading = true;
	    	const { data } = await this.$store.dispatch('task/list', {
	    		filter: {
	    			preset: 'tasks_of_job',
                    subject: this.subject,
                    grade: this.grade,
                    is_final: this.is_final,
                    option: this.option
	    		},
	    		pagination: 0
	    	});
	    	this.items = _.get(data, 'items', []);
			this.loading = false;
    	},
        print() {
            setTimeout(() => {
                this.ready = true;
                window.print();
            }, 7500);
        }
    },
    async created() {
        await this.fetchTasks();
    }
}
</script>
<style lang="scss">
@media print {
    @page {
        size: A3;
    }
}

.view--print-option{
    @media screen {
        .view--print-option__waiting{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9999;
        }
        .job-task-list{
            opacity: 0;
        }
    }
    @media print {
        .view--print-option__waiting{
            display: none!important;
        }
    }
}
</style>