<template>
	<div class="job-task-list">
		<v-skeleton-loader v-if="loading" :type="skeletonLoaderType" />
		<div v-else>
			<div v-for="(item, i) in allItems" :key="i" class="assigned-job-task pt-5">
				<div class="assigned-job-task__title fs-14 lh-17 pb-3 d-flex flex-row flex-nowrap justify-space-between align-center">
					<span class="assigned-job-task__task-number">Задание {{ _.has(item, 'extra.number') ? item.extra.number : (i + 1) }} из {{ total }}</span>
					<slot name="title.right" :item="item">
						<span 
							v-if="_.has(item, 'extra.subtitle')" 
							v-html="item.extra.subtitle"
							class="text-button"
						></span>
					</slot>
				</div>

				<examen-vpr-template 
					class="assigned-job-task__iframe"
					:model="item" 
					:key="item.id" 
					:mode="_.has(item, 'extra.mode') ? item.extra.mode : mode" 
					:disabled="disabled" 
					:allow-show-answer="allowShowAnswer"
					@complete="complete($event, item)" 
				/>
			</div>
		</div>
	</div>
</template>
<script>
import ExamenVprTemplate from './ExamenVprTemplate.vue'
export default {
    name: 'JobTaskList',
	components: { ExamenVprTemplate },
    props: {
		loading: Boolean,
    	items: Array,
		disabled: Boolean,
		mode: {
			type: String,
			default: ''
		},
        allowShowAnswer: {
            type: Boolean,
            default: null
        },
		printable: Boolean
    },
	data() {
		return {
			completed: 0,
			error: {
				show: false,
				text: ''
			}
		}
	},
    computed: {
    	skeletonLoaderType() {
    		return _.repeat(',card-heading,list-item-three-line,divider', 5).substring(1);
    	},
    	total() {
    		return _.size(this.items);
    	},
		isComplete() {
			return _.size(this.allItems) === this.completed;
		},
		allItems() {
			if(this.printable === true)
			{
				return _.compact(_.flatten(_.map(this.items, (o,k) => {
					return [
						_.assign(_.cloneDeep(o), {
							extra: {
								number: k + 1,
								mode: 'control',
								subtitle: 'Форма задания'
							}
						}),
						(o.check_type === 'auto' ? _.assign(_.cloneDeep(o), {
							extra: {
								number: k + 1,
								mode: 'answer',
								subtitle: 'Правильный ответ'
							}
						}) : null),
					]
				})));
			}
			else
				return this.items;
		}
    },
	methods: {
		complete() {
			this.completed++;
		},
		showError(error) {
			this.error.text = error;
			this.error.show = true;
		},
		closeError() {
			this.error.show = false;
			this.error.text = '';
		}
	},
	watch: {
		isComplete(val) {
			if(val === true)
				this.$emit('complete', 1);
		}
	}
}
</script>
<style lang="scss" scoped>
	.assigned-job-task{
		.assigned-job-task__task-number{
			color: #15CD9D;
			font-style: normal;
			font-weight: bold;
		}
	}

	@media print {
		.assigned-job-task{
			page-break-before: avoid;
			page-break-after: avoid;
			page-break-inside: avoid;

			.assigned-job-task__title, 
			.assigned-job-task__iframe{
				page-break-before: avoid;
				page-break-after: avoid;
				page-break-inside: avoid;
			}
		}
	}
</style>